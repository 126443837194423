import React, { useState, useContext } from 'react';
import {Howl, Howler} from 'howler';
import {RadioPlayerStatus} from './common';
import * as RadioPlayerUtils from './utils';
import './radioplayer-streams.scss'
import SoundContext from './sound-context';

export type RadioStream = {
    url: string
    title: string
    format: string
}

enum StreamFormats {
    AAC = 'AAC'
}

interface RadioPlayerStreamsProps {
    streams: Array<RadioStream>,
}

enum StreamDrawerStatuses {
    Opened,
    Closed
}

const RadioPlayerStreams: React.FC<RadioPlayerStreamsProps> = (props) => {
    const [drawerStatus, setDrawerStatus] = useState(StreamDrawerStatuses.Closed);
    const soundContext = useContext(SoundContext);

    const changeStream = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, stream: RadioStream) => {
        event.preventDefault();
        // Set status to bufferring until the sound actually starts playing
        soundContext.sound.unload();
        // Give this unload some time cause it triggers some events I cant delete
        // await sleep(100);
        soundContext.setNewSound(new Howl({
            src: RadioPlayerUtils.buildStreamUrl(stream.url, soundContext.dist).toString(),
            format: ['aac'],
            html5: true,
            autoplay: true,
        }));
        soundContext.setPlayerStatus(RadioPlayerStatus.Buffering);
    }

    const closeDrawer = () => {
        document.body.removeEventListener('click', closeDrawer);
        setTimeout( () => {
            setDrawerStatus(StreamDrawerStatuses.Closed)
        }, 10);
    }

    const toggleStreamsDrawer = (event: React.MouseEvent<HTMLInputElement>) => {
        if (drawerStatus === StreamDrawerStatuses.Closed) {
            setDrawerStatus(StreamDrawerStatuses.Opened)
            document.body.addEventListener('click', closeDrawer);
        } else {
            closeDrawer();
        }
    }

    const drawer = () => {
        if (drawerStatus === StreamDrawerStatuses.Opened) {
            return (
                <div className="rpbg-streams-drawer">
                    <ul>
                        {props.streams.map((stream, index) => {
                            if (stream.format === StreamFormats.AAC) {
                                return <li key={index} onClick={(event) => changeStream(event, stream)}>
                                    &rsaquo; {stream.title}
                                </li>
                            }
                        })}
                    </ul>
                </div>
            )
        } else {
            return <div></div>;
        }
    }

    const icon = <div className="rpbg-streams-icon" onClick={toggleStreamsDrawer}>&#8991;</div>

    return (<div>
        {icon}
        {drawer()}
    </div>);
}

export default RadioPlayerStreams;