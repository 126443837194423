import React, {useState} from 'react';
import {Howl, Howler} from 'howler';
import Slider from 'react-rangeslider'
import './radioplayer-volume.scss'
import {DEFAULT_VOLUME} from './constants';


const RadioPlayerVolumeBar: React.FC<any> = () => {

    const [value, setValue] = useState(DEFAULT_VOLUME * 100);

    const changeVolume = (value: number) => {
        setValue(value);
        Howler.volume(value / 100);
    }

    return (
        <div className="rpbg-volume-bar-wrap">
            <Slider
                min={0}
                max={100}
                value={value}
                step={1}
                tooltip={false}
                orientation="horizontal"
                onChange={changeVolume} />
        </div>);
}

export default RadioPlayerVolumeBar;