import RadioPlayerConfig from './config'

export const DIST = 'DESKTOP';

/**
 * @param elem
 */
export const parseStreamsData = (elem: HTMLElement): RadioPlayerConfig => {
    const config = new RadioPlayerConfig();
    const data = JSON.parse(elem.textContent);

    console.log(data);

    config.title = data.title;
    config.streams = [];
    for(var stream in data.allStreams) {
        console.log(stream);
        if (data.allStreams[stream].format && data.allStreams[stream].format !== "AAC") {
            // Do not add it to the streams
        } else {
            config.streams.push(data.allStreams[stream]);
        }
    }
    config.metadataId = data.metaUrlSlug;
    config.logo = data.logo;
    config.autoplay = data.autoplay;
    config.theme = data.theme || null;
    config.dist = data.dist || null;

    return config;
}

export const buildStreamUrl = (url: string, dist?: string): URL => {
    const finalUrl = new URL(url);
    const distValue = dist || DIST;
    finalUrl.searchParams.append('dist', distValue);
    return finalUrl;
}
