import React, { createContext } from 'react';
import {RadioPlayerStatus} from './common';

export default createContext({
    sound: null,
    setNewSound: (sound: any) => {},
    status: null,
    setPlayerStatus: (status: RadioPlayerStatus) => {},
    toggleSound: () => {},
    dist: null,
});