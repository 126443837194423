import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface RadioPlayerMetadataProps {
    onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
    metadataId: string
    metadataUrl: string
    logo: string
}

interface RadioPlayerMetadataCurrentSong {
    artist: string
    title: string
    id: number
    imageUrl: string
}

const RadioPlayerMetadata: React.FC<RadioPlayerMetadataProps> = (props) => {
    const [currentSong, setCurrentSong] = useState(null);

    const protocol = location.protocol !== 'https:' ? 'http://' : 'https://';

    const getCleanImageUrl = (imageUrl?: string) => {
        // Change the image to default to logo if the protocol is different
        if (imageUrl == null || imageUrl.indexOf(protocol) < 0) {
            let image;
            image = props.logo;
            // Make sure logo is the same protocol as the current protocol
            if (image.indexOf(protocol) < 0) {
                const arr = image.split('//');
                image = image.replace(arr[0], protocol.replace('//', ''));
            }
            return image;
        } else {
            return imageUrl;
        }
    }

    const fetchCurrentSong = async () => {
        let currentSong;
        await axios.get(props.metadataUrl + "=" + props.metadataId)
            .then(function (response) {
                // handle success
                const imageUrl = getCleanImageUrl(response.data.nowplaying[0].imageUrl); 

                currentSong = {
                    id: response.data.nowplaying[0].id,
                    artist: response.data.nowplaying[0].artist,
                    title: response.data.nowplaying[0].title,
                    imageUrl: imageUrl,
                };
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
        return currentSong;
    }

    const refreshCurrentSong = async () => {        
        if (document.hasFocus()) {
            const song = await fetchCurrentSong();
            setCurrentSong(song);
        }
    }

    useEffect(() => {
        refreshCurrentSong();
        const interval = setInterval(function() {
            refreshCurrentSong();
        }, 15000);
    }, [props.metadataUrl]);

    if (currentSong)
        return (<div className="rpbg-metadata-current-wrap">
            <div className="rpbg-metadata-current-artwork"><img src={currentSong.imageUrl} /></div>
            <div className="rpbg-metadata-current-info-wrap">
                <div className="rpbg-metadata-current-title"><p>{currentSong.title}</p></div>
                <div className="rpbg-metadata-current-artist"><p>{currentSong.artist}</p></div>
            </div>
        </div>);
    else
        return (<></>)
}

export default RadioPlayerMetadata;