import React, {useState, useEffect} from 'react';
import SoundContext from './sound-context';
import {RadioPlayerStatus} from './common';
import RadioPlayerConfig from './config';
import {RadioPlayer} from './radioplayer';

export const RadioPlayerSoundWrapper: React.FC<RadioPlayerConfig> = (props) => {
    // Create the sound and the setNewSound as a state here
    // so we can give them to the context and use them later in any component
    const [sound, setSound] = useState(props.sound);
    const [status, setStatus] = useState(RadioPlayerStatus.Stopped);
    const setNewSound = (newSound: any) => { setSound(newSound); }

    useEffect(() => {
        sound.on('play', () => {
            setStatus(RadioPlayerStatus.Playing);
        })
        sound.on('stop', () => {
            // sound.unload triggers a stop, but we don't want to change the button state
            // to a stop, because we only unload when we change the stream, so we want bufferring
            if (sound.state() !== 'unloaded') {
                setStatus(RadioPlayerStatus.Stopped);
            }
        })
    }, [sound]);

    const toggleSound = () => {
        if (status === RadioPlayerStatus.Playing) {
            // Since this is a stream, we need to unload it, can't just stop it
            sound.stop();
            setStatus(RadioPlayerStatus.Stopped);
        } else {
            // Play the sound.
            sound.play();
            setStatus(RadioPlayerStatus.Buffering);
        }
    }

    const setPlayerStatus = (status: RadioPlayerStatus) => {
        setStatus(status);
    }

    const dist = props.dist;

    const protocol = location.protocol !== 'https:' ? 'http' : 'https';

    return (<div>
        <SoundContext.Provider value={{
            sound,
            setNewSound,
            status,
            setPlayerStatus,
            toggleSound,
            dist
        }}>
            <RadioPlayer
                title={props.title}
                metadataId={props.metadataId}
                metadataUrl={protocol + "://meta.metacast.eu/aim/?radio"}
                streams={props.streams}
                logo={props.logo}
                theme={props.theme} />
        </SoundContext.Provider>
    </div>);
}
