import React, { useContext } from 'react';
import {RadioPlayerStatus} from './common';
import SoundContext from './sound-context';

interface RadioPlayerButtonProps {
    alt: string
}

const RadioPlayerButton: React.FC<RadioPlayerButtonProps> = (props) => {
    const soundContext = useContext(SoundContext)
    const status = soundContext.status;

    const buttonIcon = () => {
        if (status === RadioPlayerStatus.Stopped) {
            return <div className="rpbg-button-play">&#9654;</div>;
        } else if (status === RadioPlayerStatus.Buffering) {
            return <div className="rpbg-button-buffering">
                <div className="rpbg-button-buffering-icon">&#9729;</div>
            </div>
        } else {
            return <div className="rpbg-button-stop">&#9632;</div>;
        }
    }

    const buttonClassName = () => {
        return "rpbg-radioplayer-button";
    }

    return <button
        disabled={status === RadioPlayerStatus.Buffering}
        onClick={soundContext.toggleSound}
        className={buttonClassName()}>{buttonIcon()}</button>;
}

export default RadioPlayerButton;