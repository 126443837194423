import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { RadioPlayerSoundWrapper } from './radioplayer/sound-wrapper';
import * as RadioPlayerUtils from './radioplayer/utils'
import { Howl } from 'howler';

// Listening for this custom event that the HTML will
// trigger when ready for loading the radioplayer
document.addEventListener('rpbg-radioplayer-init', () => {
    if (document.getElementById('rpbg-radioplayer')) {
        const rootElement = document.getElementById('rpbg-radioplayer');
        const radioConfig = RadioPlayerUtils.parseStreamsData(rootElement);

        // Initialize howl with the first stream, then pass it to the context
        // and all other changes will be triggered from the inside
        const howlSound = new Howl({
            src: [RadioPlayerUtils.buildStreamUrl(radioConfig.streams[0].url, radioConfig.dist).toString()],
            format: ['aac'],
            html5: true,
            autoplay: radioConfig.autoplay || false,
        });

        ReactDOM.render(
            <RadioPlayerSoundWrapper {...{...radioConfig, ...{ sound: howlSound }}} />,
            rootElement
        );
    } else {
        console.log('rpbg-radioplayer not found in DOM');
    }
});
