import React, { useState, useEffect } from 'react';
import RadioPlayerButton from './radioplayer-button';
import RadioPlayerMetadata from './radioplayer-metadata';
import RadioPlayerVolumeBar from './radioplayer-volume';
import RadioPlayerStreams, { RadioStream } from './radioplayer-streams';
import './themes.scss';
import './radioplayer.scss';

export enum RadioPlayerTheme {
    'radio1-theme',
    'radio1rock-theme',
    'radioveronika-theme',
    'radionova-theme',
    'radioenergy-theme',
    'city-theme',
    'bgradio-theme',
}

interface RadioPlayerProps {
    title: string
    metadataId: string
    metadataUrl: string
    streams: Array<RadioStream>
    logo: string
    theme?: RadioPlayerTheme
}

export const RadioPlayer: React.FC<RadioPlayerProps> = (props) => {

    const getTheme = () => {
        return props.theme || 'default-theme';
    }

    return (<div className={`${getTheme()} rpbg-radioplayer`} >
        <div className="rpbg-inner-radioplayer">
            <RadioPlayerButton alt={"Play"} />
            <RadioPlayerMetadata
                logo={props.logo}
                metadataUrl={props.metadataUrl}
                metadataId={props.metadataId}
                onClick={ () => { console.log('click metadata') } }/>
            <RadioPlayerVolumeBar />
        </div>
        <div className="rpbg-outer-radioplayer">
            <RadioPlayerStreams streams={props.streams} />
        </div>
    </div>);
}