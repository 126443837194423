import { Howl } from 'howler';
import { RadioPlayerTheme } from './radioplayer';

export default class RadioPlayerConfig {
    title: string
    streams: Array<Stream>
    metadataId: string
    metadataUrl: string
    logo: string
    autoplay: boolean
    sound: Howl
    theme?: RadioPlayerTheme
    dist?: string
}

interface Stream {
    url: string
    title: string
    format: string
}
